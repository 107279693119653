import "bootstrap/dist/js/bootstrap.bundle.min"
global.moment = require("moment")
import "moment-timezone"

import "blueimp-file-upload/js/jquery.iframe-transport.js"
import "blueimp-file-upload/js/jquery.fileupload.js"
import "./lib/jquery.fileupload-dragover.js"
import "./lib/dataTables.responsive"
import "./lib/dataTables.moment"

import swal from "sweetalert2"
import "select2"
import "select2/dist/js/i18n/de"

window.Swal = swal

console.log("JS: Footer libraries loaded.")
