$(document).bind('dragover', function (e) {

    var dropZone = $('.dropzone'), foundDropzone, timeout = window.dropZoneTimeout;

    if (!timeout) {
        dropZone.addClass('in');
    } else {
        clearTimeout(timeout);
    }

    var found = false, node = e.target;

    do {

        if ($(node).hasClass('dropzone')) {
            found = true;
            foundDropzone = $(node);
            break;
        }

        node = node.parentNode;

    } while (node != null);

    dropZone.removeClass('hover');

    if (found) {
        foundDropzone.addClass('in hover');
    }

    window.dropZoneTimeout = setTimeout(function () {
        window.dropZoneTimeout = null;
        dropZone.removeClass('in hover');
    }, 100);

});

$(document).bind('drop dragover', function (e) {
    e.preventDefault();
});